import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import MobileMenu from './MobileMenu';
import fetchHome from '../fetch/home';
class NavBar extends Component{

    constructor(props){
        super(props);
        this.state = {
            data: this.props.data || null
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.mobileMenuElement = React.createRef();
    }

    getData(){
        if (this.state.data) {
            return;
        }
        fetchHome()
            .then(response => response.json())
            .then((response) => { 
                if (response.data) {
                    this.setState({
                        data: response.data[0]
                    });
                } 
            });
    }

    activeMobileMenu = () => {
        this.mobileMenuElement.current.toggleMobileMenu();
    }

    handleScroll() {
        if(this.mount){
            this.setState({scroll: window.scrollY});
        }
    }
  
    componentDidMount() {
        this.getData();
        this.mount = true;
        const el = document.querySelector('nav');
        this.setState({top: el.offsetTop, height: el.offsetHeight});
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentDidUpdate() {
        this.state.scroll > this.state.top ? 
        document.body.style.paddingTop = `${this.state.height}px` :
        document.body.style.paddingTop = 0;
    }

    componentWillUnmount(){
        this.mount = false;
    }

    render(){
        return(
            <div>
                <div className={`header-area header-sticky header-sticky--default ${this.state.scroll > this.state.top ? "is-sticky" : ""}`}>
                    <div className="header-area__desktop header-area__desktop--default">
                    <div className="header-top-bar">
                        <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                            <div className="top-bar-left-wrapper">
                                <div className="social-links social-links--white-topbar d-inline-block">
                                {
                                    this.state.data &&
                                    <ul>
                                        { this.state.data.facebook && <li><a href={this.state.data.facebook}><i className="fa fa-facebook" /></a></li>}
                                        { this.state.data.twitter && <li><a href={this.state.data.twitter}><i className="fa fa-twitter" /></a></li>}
                                        { this.state.data.vimeo && <li><a href={this.state.data.vimeo}><i className="zmdi zmdi-vimeo" /></a></li>}
                                        { this.state.data.linkedin && <li><a href={this.state.data.linkedin}><i className="zmdi zmdi-linkedin-box" /></a></li>}
                                        { this.state.data.skype && <li><a href={this.state.data.skype}><i className="zmdi zmdi-skype" /></a></li>}
                                        { this.state.data.instagram && <li><a href={this.state.data.instagram}><i className="fa fa-instagram" /></a></li>}
                                        { this.state.data.youtube && <li><a href={this.state.data.youtube}><i className="zmdi zmdi-youtube" /></a></li>}
                                    </ul>
                                }
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-8">
                            <div className="top-bar-right-wrapper">
                                <a href={(this.state.data && this.state.data.boton_superior_link) || '/contact-us'} className="ht-btn ht-btn--default d-inline-block">{ (this.state.data && this.state.data.boton_superior_texto) || 'PEDIR PRESUPUESTO'}</a>
                             </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="header-info-area">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                <div className="header-info-wrapper align-items-center">
                                    <div className="logo">
                                        <Link to = {`${process.env.PUBLIC_URL}/`}>
                                            { this.state.data &&
                                              <img src={this.state.data.logo && this.state.data.logo.data.full_url || '/assets/img/logo/logo.png'} className="img-fluid" alt="Logo" />
                                            }
                                        </Link>
                                    </div>
                                    <div className="header-contact-info">
                                    <div className="header-info-single-item">
                                        <div className="header-info-single-item__icon">
                                        <i className="zmdi zmdi-smartphone-android" />
                                        </div>
                                        <div className="header-info-single-item__content">
                                            <h6 className="header-info-single-item__title">Telefono</h6>
                                            <p className="header-info-single-item__subtitle">{this.state.data && this.state.data.telefono}</p>
                                        </div>
                                    </div>
                                    <div className="header-info-single-item">
                                        <div className="header-info-single-item__icon">
                                        <i className="zmdi zmdi-home" />
                                        </div>
                                        <div className="header-info-single-item__content">
                                        <h6 className="header-info-single-item__title">Direccion</h6>
                                        <p className="header-info-single-item__subtitle">{this.state.data && this.state.data.direccion}</p>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="mobile-navigation-icon" id="mobile-menu-trigger" onClick={this.activeMobileMenu}>
                                        <i />
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                    </div>
                    <div className="header-navigation-area default-bg">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="header-navigation header-navigation--header-default position-relative">
                                        <div className="header-navigation__nav position-static">
                                        <nav>
                                            <ul>
                                            <li>
                                            {   this.state.data && 
                                                <Link to={`${process.env.PUBLIC_URL}/`}> {(this.state.data.menu_home && this.state.data.menu_home.toUpperCase()) || 'HOME'} </Link>
                                            }
                                            </li>
                                            <li>
                                            {   this.state.data && 
                                                <Link to={`${process.env.PUBLIC_URL}/about-us`}>{(this.state.data && this.state.data.menu_about_us && this.state.data.menu_about_us.toUpperCase()) || 'ABOUT'}</Link>
                                            }
                                            </li>
                                            <li>
                                            {   this.state.data && 
                                                <Link to={`${process.env.PUBLIC_URL}/services`}>{(this.state.data && this.state.data.menu_services && this.state.data.menu_services.toUpperCase()) || 'SERVICIOS'}</Link>
                                            }
                                            </li>
                                            <li>
                                            {   this.state.data && 
                                                <Link to={`${process.env.PUBLIC_URL}/projects`} >{(this.state.data && this.state.data.menu_projects && this.state.data.menu_projects.toUpperCase()) || 'PROYECTOS'}</Link>
                                            }
                                            </li>
                                            <li>
                                            {   this.state.data &&
                                                <Link to={`${process.env.PUBLIC_URL}/blog`}>{(this.state.data && this.state.data.menu_blog && this.state.data.menu_blog.toUpperCase()) || 'BLOG'}</Link>
                                            }
                                            </li>
                                            <li>
                                            {   this.state.data &&
                                                <Link to={`${process.env.PUBLIC_URL}/contact-us`}>{(this.state.data.menu_contact_us && this.state.data.menu_contact_us.toUpperCase()) || 'CONTACTO'}</Link>
                                            }
                                            </li>
                                            </ul>
                                        </nav>
                                        </div>
                                    </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <MobileMenu ref={this.mobileMenuElement} />

            </div>
        )
    }
}


export default NavBar;
import React, {Component} from 'react';
import {animateScroll as scroll } from 'react-scroll';
import fetchHome from '../fetch/home';

class Footer extends Component{

    
    constructor(props){
        super(props);
        this.state = {
            data: this.props.data || null
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    handleScroll() {
        if(this.mount){
            this.setState({scroll: window.scrollY});
        }
    }

    scrollToTop (){
        scroll.scrollToTop();
    }
  
    getData(){
        if (this.state.data) {
            return;
        }
        fetchHome()
            .then(response => response.json())
            .then((response) => { 
                if (response.data) {
                    this.setState({
                        data: response.data[0]
                    });
                } 
            });
    }

    componentDidMount() {
        this.getData();
        this.mount = true;
        const el = document.querySelector('nav');
        this.setState({top: el.offsetTop, height: el.offsetHeight});
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentDidUpdate() {
        this.state.scroll > this.state.top ? 
        document.getElementById("scroll-top").classList.add("show") :
        document.getElementById("scroll-top").classList.remove("show") ;
    }

    componentWillUnmount(){
        this.mount = false;
    }

    render(){
        return(
            <div>
                <div className="footer-area dark-bg">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-content-wrapper section-space--inner--100">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-md-12">
                                <div className="footer-intro-wrapper">
                                    <div className="footer-logo">                                        
                                        <a href='/'>
                                            { this.state.data && 
                                              <img src={this.state.data.logo_blanco && this.state.data.logo_blanco.data.full_url || '/assets/img/logo/logo-light.png'} className="img-fluid" alt="" />
                                            }
                                        </a>
                                    </div>
                                    <div className="footer-desc">
                                    {this.state.data && this.state.data.descripcion_footer}
                                    </div>
                                </div>
                                </div>
                                <div className="col-xl-3 offset-xl-1 col-lg-3 col-md-5">
                                <div className="footer-widget">
                                    <h4 className="footer-widget__title">LINKS UTILES</h4>
                                    <ul className="footer-widget__navigation">
                                    <li><a href='/'>{(this.state.data && this.state.data.menu_home) || 'Home'}</a></li>
                                    <li><a href='/blog'>{(this.state.data && this.state.data.menu_blog) || 'Novedades'}</a></li>
                                    <li><a href='/services'>{(this.state.data && this.state.data.menu_services) || 'Servicios'}</a></li>
                                    <li><a href='/projects'>{(this.state.data && this.state.data.menu_projects) || 'Proyectos'}</a></li>
                                    <li><a href='/contact-us'>{(this.state.data && this.state.data.menu_contact_us) || 'Contactanos'}</a></li>
                                    </ul>
                                </div>
                                </div>
                                <div className="col-xl-4 offset-xl-1 col-lg-6 col-md-5">
                                <div className="footer-widget mb-0">
                                    <h4 className="footer-widget__title">CONTACTANOS</h4>
                                    <div className="footer-widget__content">
                                    <p className="address">{this.state.data && this.state.data.direccion}</p>
                                    {
                                        this.state.data && 
                                        <ul className="contact-details">
                                            { this.state.data.celular && <li>Celular: {this.state.data.celular}</li>}
                                            { this.state.data.celular_2 && <li>Celular: {this.state.data.celular_2}</li>}
                                            { this.state.data.celular_3 && <li>Celular: {this.state.data.celular_3}</li>}
                                            { this.state.data.telefono && <li>Teléfono: {this.state.data.telefono}</li>}
                                            { this.state.data.email && <li>Email: {this.state.data.email}</li>}
                                        </ul>
                                    }
                                    
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                        <div className="footer-copyright-wrapper">
                        <div className="footer-copyright text-center">
                        Copyright © 2022. All right reserved
                        </div>
                    </div>
                </div>
                <button className="scroll-top" id="scroll-top" onClick={this.scrollToTop}>
                    <i className="ion-android-arrow-up" />
                </button>
            </div>
        )
    }
}


export default Footer;
import React, {Component} from 'react';
import fetchBlog from '../fetch/home-blog';

class BlogGrid extends Component{
    constructor(props){
        super(props);
        this.state = {
          data: []
        }
      }
    
    getData(){
        fetchBlog()
            .then(response => response.json())
            .then((response) => {  
                this.setState({
                    data: response.data
                });
            });
    }

    componentDidMount(){
        this.getData();
    }

    render(){
        
        if (!this.state.data.length) {
            return (<div/>);
        }

        let DataList = this.state.data.map((val, i) => {
            return(
                <div className="col-lg-4" key={i}>
                    <div className="blog-post-slider__single-slide blog-post-slider__single-slide--grid-view">
                        <div className="blog-post-slider__image section-space--bottom--30">
                            <a href={`${process.env.PUBLIC_URL}/blog-details/${val.id}`}><img src={val.portada.data.full_url} className="img-fluid" alt="" /></a>
                            </div>
                            <div className="blog-post-slider__content">
                            <p className="post-date"> {val.fecha_creacion}</p>
                            <h3 className="post-title">
                                <a href={`${process.env.PUBLIC_URL}/blog-details/${val.id}`}>{val.titulo}</a>
                            </h3>
                            <p className="post-excerpt">{val.descripcion_corta}</p>
                            <a href={`${process.env.PUBLIC_URL}/blog-details/${val.id}`} className="see-more-link">VER MAS</a>
                        </div>
                    </div>
                </div>
            )
        });

        return(
            <div>
                <div className={`blog-grid-area section-space--inner--120 ${this.props.background}`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title-area text-center">
                                <h2 className="section-title section-space--bottom--50">Novedades</h2>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="blog-grid-wrapper">
                                <div className="row">
                                    {DataList}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default BlogGrid;
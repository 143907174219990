import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import fetchServices from '../fetch/home-services';

class ServiceTabExample extends Component{
    constructor(props){
        super(props);
        this.state = {
          data: []
        }
      }
    
    getData(){
        fetchServices()
            .then(response => response.json())
            .then((response) => {  
                this.setState({
                    data: response.data
                });
            });
    }

    componentDidMount(){
        this.getData();
    }

    render(){
        if (!this.state.data.length) {
            return (<div/>);
        }
        
        let serviceTabMenuDatalist = this.state.data.map((val, i)=>{
            return(
                <Tab key={i}>  <span className="icon"><i className={val.iconName} /></span> <span className="text">{val.titulo}</span></Tab>
            )
        });


        let serviceTabContentDatalist = this.state.data.map((val, i)=>{
            return(
                <TabPanel key={i}>
                    <div className="service-tab__single-content-wrapper" style={{ backgroundImage: `url(${val.portada.data.full_url})` }}>
                        <div className="service-tab__single-content">
                            <h3 className="service-tab__title">{val.titulo}</h3>
                            <p className="service-tab__text">{val.descripcion_corta}</p>
                            <a href={`${process.env.PUBLIC_URL}/service-details/${val.id}`} className="see-more-link">VER MAS</a>
                        </div>
                    </div>
                </TabPanel>
            )
        });

        return(
            <div>
                <div className="service-tab-area section-space--inner--120">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area text-center">
                        <h2 className="section-title section-space--bottom--50">Nuestros Servicios</h2>
                        </div>
                    </div>
                    <div className="col-lg-12">                        
                        <div className="service-tab-wrapper">
                        <Tabs>
                            <div className="row no-gutters">
                                <div className="col-md-4">
                                    <TabList>
                                        <div className="service-tab__link-wrapper">
                                            {serviceTabMenuDatalist}
                                        </div>
                                    </TabList>
                                </div>
                                <div className="col-md-8">
                                    {serviceTabContentDatalist}
                                </div>
                            </div>
                        </Tabs>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}

export default ServiceTabExample;
import React, {Component} from 'react';
import Swiper from 'react-id-swiper';
import fetchBrands from '../fetch/home-brands';

class BrandLogoSlider extends Component{
    constructor(props){
        super(props);
        this.state = {
          data: []
        }
      }
    
    getData(){
        fetchBrands()
            .then(response => response.json())
            .then((response) => {  
                this.setState({
                    data: response.data
                });
            });
    }

    componentDidMount(){
        this.getData();
    }

    render(){
        if (!this.state.data.length) {
            return (<div/>);
        }
        const params = {
            slidesPerView : 4,
            loop: true,
            speed: 1000,
            spaceBetween : 30,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },

            breakpoints: {
                1499:{
                    slidesPerView : 4
                },

                991:{
                    slidesPerView : 3
                },

                767:{
                    slidesPerView : 3

                },

                575:{
                    slidesPerView : 2
                }
            }
        }

        let DataList = this.state.data.map((val, i)=>{
            return(
                <div className="swiper-slide brand-logo-slider__single" key={i}>
                    <div className="image text-center">
                        <a href={val.link}>
                            <img src={val.imagen.data.full_url} className="img-fluid" alt="" style={{maxHeight:'150px'}}/>
                        </a>
                    </div>
                </div>
            )
        });


        return(
            <div>
                <div className={`brand-logo-slider-area section-space--inner--60`}>
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="brand-logo-slider__container-area">
                            <Swiper {...params}>
                                {DataList}
                            </Swiper>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}

export default BrandLogoSlider;
import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Sidebar from './components/Sidebar';
import ServiceGallery from './components/ServiceGallery';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import fetchServiceDetails from '../fetch/services-details';

class ServiceDetails extends Component{
    constructor(props){
        super(props);
        this.state = {
          data: null
        }
    }

    getData(){
        fetchServiceDetails(this.props.match.params.id)
            .then(response => response.json())
            .then((response) => {
                if (response.data) {
                    this.setState({
                        data: response.data
                    });
                }
            });
    }

    componentDidMount(){
        this.getData();
    }

    render(){
        return(
            <div>
                <NavBar/>

                {   
                    this.state.data &&
                    <div>
                    <div className="breadcrumb-area breadcrumb-bg" style={{backgroundImage: `url(${this.state.data.portada.data.full_url})`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="page-banner text-center">
                                        <h1>{this.state.data.titulo}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="page-wrapper section-space--inner--120">
                        <div className="service-section">
                            <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-12 order-1 order-lg-2">
                                <div className="service-details">
                                    <ServiceGallery serviceId={this.props.match.params.id}/>

                                    <div className="content section-space--top--30">
                                    <div className="row">
                                        <div className="col-12">
                                        <h2>{this.state.data.titulo}</h2>
                                        <p>{this.state.data.descripcion}</p>
                                        </div>
                                        <div className="col-lg-6 col-12 section-space--top--30">
                                        <h3>{this.state.data.titulo_seccion_1}</h3>
                                        <p>{this.state.data.contenido_seccion_1}</p>
                                        </div>
                                        <div className="col-lg-6 col-12 section-space--top--30">
                                        <h3>{this.state.data.titulo_seccion_2}</h3>
                                        <p>{this.state.data.contenido_seccion_2}</p>
                                        </div>
                                        <div className="col-lg-6 col-12 section-space--top--30">
                                        <h3>{this.state.data.titulo_seccion_3}</h3>
                                        <p>{this.state.data.contenido_seccion_3}</p>
                                        </div>
                                        <div className="col-lg-6 col-12 section-space--top--30">
                                        <h3>{this.state.data.titulo_seccion_4}</h3>
                                        <p>{this.state.data.contenido_seccion_4}</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="col-lg-4 col-12 order-2 order-lg-1">
                                    <Sidebar />
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                }

                <Footer/>

                <MobileMenu/>

            </div>
        )
    }
}


export default ServiceDetails;
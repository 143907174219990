import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import FeatureIcon from '../components/FeatureIcon';
import Funfact from '../components/Funfact';
import TeamMemberGrid from '../components/TeamMemberGrid';
import TestimonialSlider from '../components/TestimonialSlider';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import ModalVideo from 'react-modal-video'
import fetchAboutUs from '../fetch/about-us';

function getVideoId(link = '') {
    if (link.indexOf('v=') !== -1) {
        const id = link.split('v=')[1];
        return id.split('&')[0];
    }

    if (link.indexOf('youtu.be') !== -1) {
        const id = link.split('outu.be/')[1];
        return id.split('?')[0];
    }
    return "";
}

class About extends Component{

    constructor () {
        super()
        this.state = {
          data: null,
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
      }
     
      openModal () {
        this.setState({isOpen: true})
      }

      getData(){
        fetchAboutUs()
            .then(response => response.json())
            .then((response) => {
                if (response.data) {
                    this.setState({
                        data: response.data[0]
                    });
                }
            });
    }

    componentDidMount(){
        this.getData();
    }

    render(){
        if (!this.state.data) {
            return (<div/>);
        }
        return(
            <div>
                <NavBar/>
                <div className="breadcrumb-area breadcrumb-bg" style={{backgroundImage: `url(${this.state.data.portada.data.full_url})`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>{this.state.data.encabezado}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-wrapper section-space--inner--top--120">
                <div className="about-section section-space--inner--bottom--120">
                <div className="container">
                    <div className="row row-25 align-items-center">
                    <div className="col-lg-6 col-12 mb-30">
                        <div className="about-image-two">
                        <img src={this.state.data.imagen_video && this.state.data.imagen_video.data.full_url} alt="" />
                            <span className="video-popup">
                                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={getVideoId(this.state.data.link_video)} onClose={() => this.setState({isOpen: false})} />
                                <button onClick={this.openModal}>
                                    <i className="ion-ios-play-outline" />
                                </button>
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 mb-30">
                        <div className="about-content-two">
                        <h3>{this.state.data.bienvenida}</h3>
                        <h1>{this.state.data.titulo}</h1>
                        <h4>{this.state.data.subtitulo}</h4>
                        <p>{this.state.data.descripcion}</p>
                        <a href={this.state.data.link_boton} className="ht-btn--default ht-btn--default--dark-hover section-space--top--20">{this.state.data.texto_boton}</a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <FeatureIcon background = "grey-bg"/>
                <div className="about-section section-space--inner--120">
                <div className="container">
                    <div className="about-wrapper row">
                    <div className="col-sm-6 col-12 order-1 order-lg-2">
                        <div className="about-image about-image-1">
                        { this.state.data.imagen_1 && 
                          <img src={this.state.data.imagen_1.data.full_url} alt="" />
                        }
                        </div>
                    </div>
                    <div className="col-sm-6 col-12 order-2 order-lg-3">
                        <div className="about-image about-image-2">
                        { this.state.data.imagen_2 && 
                          <img  src={this.state.data.imagen_2.data.full_url} alt="" />
                        }
                          </div>
                    </div>
                    <div className="col-lg-6 col-12 order-3 order-lg-1">
                        <div className="about-content about-content-1">
                        <h1>{this.state.data.segundo_titulo}</h1>
                        <p>{this.state.data.texto_1}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 order-4">
                        <div className="about-content about-content-2">
                        <h1>{this.state.data.tercer_titulo}</h1>
                        <p>{this.state.data.texto_2}</p>
                        {   (this.state.data.texto_boton_2 && this.state.data.link_boton_2) &&
                            <a href={this.state.data.link_boton_2} className="ht-btn--default ht-btn--default--dark-hover section-space--top--20">{this.state.data.texto_boton_2}</a>
                        }
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <Funfact fondo={this.state.data.fondo_contador && this.state.data.fondo_contador.data.full_url}/>
                
                <TeamMemberGrid titulo={this.state.data.equipo_titulo}/>

                <TestimonialSlider/>

                <BrandLogoSlider background = "" />

                </div>
                
                <Footer/>

                <MobileMenu/>

            </div>
        )
    }
}


export default About;
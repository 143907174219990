import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import ProjectsGrid from './components/ProjectsGrid';
import fetchProject from '../fetch/project';
class Projects extends Component{
    constructor(props){
        super(props);
        this.state = {
          data: null
        }
      }
    
    getData(){
        fetchProject()
            .then(response => response.json())
            .then((response) => {  
                if (response.data) {
                    this.setState({
                        data: response.data[0]
                    });
                }
            });
    }

    componentDidMount(){
        this.getData();
    }


    render(){
        return(
            <div>
                <NavBar/>
                {   this.state.data &&
                    <div className="breadcrumb-area breadcrumb-bg" style={{backgroundImage: `url(${this.state.data.portada && this.state.data.portada.data.full_url})`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="page-banner text-center">
                                        <h1>{this.state.data.titulo}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                
                <ProjectsGrid/>

                <Footer/>

                <MobileMenu/>
            </div>
        )
    }
}


export default Projects;
import PATHS from '../constants';

function fetchProjectImages(id) {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    return fetch(PATHS.PROJECT_IMAGES(id), {
        method: 'GET',
        dataType: 'json',
        headers,
    });
};

export default fetchProjectImages;
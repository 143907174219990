import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import PhotoGallery from './components/PhotoGallery';
import fetchProjectDetails from '../fetch/project-details';
class ProjectDetails extends Component{
    constructor(props) {
        super(props);
        this.state = {
            data: null
        };
    }
    
    getData(){
        const projectId = this.props.match.params.id;
        fetchProjectDetails(projectId)
            .then(response => response.json())
            .then((response) => {
                if (response.error) {
                    this.props.history.push('/');
                    return;
                }
                this.setState({
                    data: response.data
                });
            });
    }

    componentDidMount(){
        this.getData();
    }

    render(){
        return(
            this.state.data &&
            <div>
                <NavBar/>
                <div className="breadcrumb-area breadcrumb-bg" style={{backgroundImage: `url(${this.state.data.portada.data.full_url})`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>{this.state.data.titulo}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-wrapper section-space--inner--120">
                    <div className="project-section">
                        <div className="container">
                        <div className="row">
                            {/*
                            <div className="col-12 section-space--bottom--40">
                                <div className="project-image"><img src={this.state.data.portada.data.full_url} className="img-fluid" alt="" /></div>
                            </div>
                            */}
                            <div className="col-lg-4 col-12 section-space--bottom--30">
                                <div className="project-information">
                                    <h3>Información del proyecto</h3>
                                    <ul>
                                        { this.state.data.cliente && <li><strong>Cliente:</strong> <a href={this.state.data.cliente_link || '#'}>{this.state.data.cliente}</a></li>}
                                        { this.state.data.locacion && <li><strong>Locación:</strong>{this.state.data.locacion}</li>}
                                        { this.state.data.anio && <li><strong>Año:</strong> {this.state.data.anio}</li>}
                                        { this.state.data.presupuesto && <li><strong>Presupuesto:</strong> {this.state.data.presupuesto}</li>}
                                        { this.state.data.encargado && <li><strong>Encargado:</strong> {this.state.data.encargado}</li>}
                                        { this.state.data.sector && <li><strong>Sector:</strong> {this.state.data.sector}</li>}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                                <div className="project-details">
                                    <h2>{this.state.data.titulo}</h2>
                                    <p>{this.state.data.descripcion}</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <PhotoGallery projectId={this.props.match.params.id}/>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <Footer/>
                <MobileMenu/>
            </div>
        )
    }
}


export default ProjectDetails;
import React, {Component} from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import fetchCounter from '../fetch/about-us-counter';

class Funfact extends Component{
    constructor (props) {
        super(props);
        this.state = {
          data: [],
          didViewCountUp: false
        }
      }

      getData(){
        fetchCounter()
            .then(response => response.json())
            .then((response) => {
                if (response.data) {
                    this.setState({
                        data: response.data
                    });
                }
            });
    }

    componentDidMount(){
        this.getData();
    }
    
    
      onVisibilityChange = isVisible => {
        if (isVisible) {
          this.setState({didViewCountUp: true});
        }
      }
    
    render(){
        if (!this.state.data.length) {
            return (<div/>);
        }

        let DataList = this.state.data.map((val, i)=>{
            return(
                <div className="single-fact col-md-3 col-6 section-space--bottom--30" key={i}>
                    <img src={val.icono.data.full_url} alt="" />
                    <h1 className="counter">
                    <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                        <CountUp end={this.state.didViewCountUp ? val.cantidad : 0} />
                    </VisibilitySensor>
                    </h1>
                    <h4>{val.titulo}</h4>
                </div>
            )
        });

        return(
            <div>
                <div className="funfact-section section-space--inner--100 funfact-bg" style={{backgroundImage: `url(${this.props.fondo})`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="fun-fact-wrapper">
                                    <div className="row">
                                        {DataList}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of fun fact area  ====================*/}

            </div>
        )
    }
}

export default Funfact;
import React, { Component } from 'react';
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import fetchProjectImages from '../../fetch/project-images';

class PhotoGallery extends Component{
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    getData() {
        fetchProjectImages(this.props.projectId)
            .then(response => response.json())
            .then(response => {
                this.setState({
                    data: response.data
                });
            });
    }

    componentDidMount() {
        this.getData();
    }

    render(){
        if (!this.state.data.length) {
            return (<div/>);
        }

        const PhotoItem = ({ image, group }) => (
            <div className="col-xl-3 col-lg-4 col-sm-6 col-12 section-space--top--10">
                <LightgalleryItem group={group} src={image}>
                    <button className="gallery-item single-gallery-thumb">
                        <img src={image} className="img-fluid" alt="" /><span className="plus" />
                    </button>
                </LightgalleryItem>
            </div>
        );
        

        return(
            <div>
                <LightgalleryProvider>
                    <div className="row row-5">
                        {this.state.data.map((p, idx) => {
                        
                        return (
                            <PhotoItem key={idx} image={p.imagen.data.full_url} group="group1" />
                        )})}
                    </div>
                </LightgalleryProvider>
            </div>
        )
    }
}

export default PhotoGallery;
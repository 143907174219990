import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Sidebar from './components/Sidebar';
import BlogPostGrid from './components/BlogPostGrid';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import fetchBlogPortada from '../fetch/blog-portada';
import fetchBlog from '../fetch/home-blog';

class BlogLeftSidebar extends Component{
    constructor(props){
        super(props);
        this.state = {
          portada: [],
          posts: []
        };
        this.filterPosts = this.filterPosts.bind(this);
        this.filterPostsByCategory = this.filterPostsByCategory.bind(this);
        this.filterPostsByTag = this.filterPostsByTag.bind(this);
    }
    
    getBlogPortada(){
        fetchBlogPortada()
            .then(response => response.json())
            .then((response) => {
                if (response.data) {
                    this.setState({
                        portada: response.data[0]
                    });
                }
            });
    }

    getPosts(extraQuery = "") {
        fetchBlog(extraQuery)
            .then(response => response.json())
            .then((response) => { 
                if (response.data) {
                    this.setState({
                        posts: response.data
                    });
                } 
            });
    }

    filterPosts(searchText) {
        const extraQuery = searchText ? `&filter[titulo][like]=${searchText}&filter[descripcion_corta][like]=${searchText}` : "";
        this.getPosts(extraQuery);
    }

    filterPostsByCategory(category) {
        const extraQuery = category ? `&filter[categoria][eq]=${category}` : "";
        this.getPosts(extraQuery);
    }

    filterPostsByTag(tag) {
        const extraQuery = tag ? `&filter[etiquetas][in]=${tag}` : "";
        this.getPosts(extraQuery);
    }

    componentDidMount(){
        this.getBlogPortada();
        this.getPosts();
    }

    render(){

        return(
            <div>

                <NavBar/>
                {
                    this.state.portada &&
                    <div className="breadcrumb-area breadcrumb-bg" style={{backgroundImage: this.state.portada.portada && `url(${this.state.portada.portada.data.full_url})`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="page-banner text-center">
                                        <h1>{this.state.portada.titulo}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }   
                
                <div className="page-wrapper section-space--inner--120">
                <div className="blog-section">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-12 order-1 order-lg-2">
                            {/* blog post grid */}
                            <BlogPostGrid posts={this.state.posts}/>
                        {/*
                        <div className="row ">
                            <div className="col">
                            <ul className="page-pagination section-space--top--30">
                                <li><a href="/"><i className="fa fa-angle-left" /> Prev</a></li>
                                <li className="active"><a href="/">01</a></li>
                                <li><a href="#">02</a></li>
                                <li><a href="#">03</a></li>
                                <li><a href="#"><i className="fa fa-angle-right" /> Next</a></li>
                            </ul>
                            </div>
                        </div>
                        */}
                        </div>
                        <div className="col-lg-4 col-12 order-2 order-lg-1">
                            {/* blog sidebar */}
                            <Sidebar
                                filterPosts={this.filterPosts}
                                filterPostsByCategory={this.filterPostsByCategory}
                                filterPostsByTag={this.filterPostsByTag}
                            />

                        </div>
                    </div>
                    </div>
                </div>
                </div>

                {/*====================  End of blog page content  ====================*/}


                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default BlogLeftSidebar;
import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import fetchTestimonial from '../fetch/home-testimonial';

class TestimonialSlider extends Component{
    constructor(props){
        super(props);
        this.state = {
          data: []
        };
      }

    getData(){
        fetchTestimonial()
            .then(response => response.json())
            .then((response) => {  
                this.setState({
                    data: response.data
                });
            });
    }

    componentDidMount(){
        this.getData();
    }

    render(){
        if (!this.state.data.length) {
            return (<div/>);
        }

        const params = {
            slidesPerView : 1,
            loop: true,
            speed: 1000,
            effect: 'fade',
            autoplay: {
                delay: 3000
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            renderPagenation: () => (
                <div className="swiper-pagination"></div>
              ),
        }

        let DataList = this.state.data.map((val, i)=>{
            return(
                <div className="swiper-slide testimonial-slider__single-slide" key={i}>
                    <div className="author">
                        <div className="author__image">
                            <img src={val.foto.data.full_url} alt="" style={{width:'100px', height: '100px'}}/>
                        </div>
                        <div className="author__details">
                            <h4 className="name">{val.nombre}</h4>
                            <div className="designation">{val.designacion}</div>
                        </div>
                    </div>
                    <div className="content">
                        {val.texto}
                    </div>
                </div>
            )
        });
        
        return(
            <div>
                <div className="testimonial-slider-area testimonial-slider-area-bg section-space--inner--120" style={{backgroundImage: `url(${this.props.fondo})`}}>
                <div className="container">
                    <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="testimonial-slider">
                        <div className="testimonial-slider__container-area" >
                            <Swiper {...params}>
                                {DataList}
                            </Swiper>
                            <div className="swiper-pagination"></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}

export default TestimonialSlider;
import React, { Component } from 'react';

class BlogPostGrid extends Component{
    constructor(props){
        super(props);
    }

    render(){
        let Datalist = this.props.posts.map((val, i)=>{
            return(
                <div className="col-sm-6 col-12" key={i}>
                    <div className="blog-post-slider__single-slide blog-post-slider__single-slide--grid-view">
                        <div className="blog-post-slider__image section-space--bottom--30">
                            <a href={`${process.env.PUBLIC_URL}/blog-details/${val.id}`}><img src={val.portada.data.full_url} className="img-fluid" alt="" /></a>
                        </div>
                        <div className="blog-post-slider__content">
                            <p className="post-date"> {val.fecha_creacion}</p>
                            <h3 className="post-title">
                                <a href={`${process.env.PUBLIC_URL}/blog-details/${val.id}`}>{val.titulo}</a>
                            </h3>
                            <p className="post-excerpt">{val.descripcion_corta}</p>
                            <a href={`${process.env.PUBLIC_URL}/blog-details/${val.id}`} className="see-more-link">VER MAS</a>
                        </div>
                    </div>
                </div>
            )
        });

        return(
            <div>
                <div className="row">
                    {Datalist}
                </div>
            </div>
        )
    }
}


export default BlogPostGrid;
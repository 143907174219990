import PATHS from '../constants';

function fetchBlog(extraQuery = "") {
    const url = `${PATHS.HOME_BLOG}${extraQuery}`;
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    return fetch(url, {
        method: 'GET',
        dataType: 'json',
        headers,
    });
};

export default fetchBlog;
import React, { Component } from 'react';
import fetchServices from '../../fetch/services';


class Sidebar extends Component{
    constructor(props){
        super(props);
        this.state = {
          data: []
        }
    }

    getData(){
        fetchServices()
            .then(response => response.json())
            .then((response) => {  
                this.setState({
                    data: response.data
                });
            });
    }

    componentDidMount(){
        this.getData();
    }
    render(){
        if (!this.state.data) {
            return (<div/>);
        }

        let Datalist = this.state.data.map((val, i)=>{
            return(
            <li key={i}><a href={`/service-details/${val.id}`}>{val.icono && <i className={val.icono} />}{val.titulo}</a></li>
            )
        })

        return(
            <div>
                <div className="sidebar-wrapper">
                    <div className="sidebar">
                        <h3 className="sidebar-title">Servicios</h3>
                        <ul className="sidebar-list">
                            {Datalist}
                        </ul>
                    </div>
                    {   (this.props.folletoPDF || this.props.folletoDOC) &&
                        <div className="sidebar">
                            <h3 className="sidebar-title">Download brochure</h3>
                                <ul className="sidebar-list">
                                {this.props.folletoPDF && <li><a href={this.props.folletoPDF.data.full_url}><i className="fa fa-file-pdf-o" />Folleto .PDF</a></li>}
                                {this.props.folletoDOC && <li><a href={this.props.folletoDOC.data.full_url}><i className="fa fa-file-word-o" />Folleto .DOC</a></li>}
                                </ul>
                        </div>
                    }
                    
                </div>
            </div>
        )
    }
}

export default Sidebar;
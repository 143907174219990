import React, { Component } from 'react';

class BlogPostContent extends Component{
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
    }
    render() {
        const {post} = this.props;
        let tagDataList = (post.etiquetas || []).map((etiqueta, i)=> {
            return(
                <li key={i}><a href="">{etiqueta}</a></li> // eslint-disable-line
            )
        });

        return(
            <div>
                <div className="row">
                    <div className="blog-details col-12">
                    <div className="blog-inner">
                        <div className="media">
                            <div className="image">
                                <img src={post.portada && post.portada.data.full_url} alt="" />
                            </div>
                        </div>
                        <div className="content">
                        <ul className="meta">
                            <li>Por <a href="blog-left-sidebar">{post.autor}</a></li>
                            <li>{post.fecha_creacion}</li>
                        </ul>
                        <h2 className="title">{post.titulo}</h2>
                        <div className="desc section-space--bottom--30">
                            {post.descripcion}
                        </div>
                        { !!tagDataList.length &&
                            <ul className="tags">
                                <li><i className="fa fa-tags" /></li>
                                {tagDataList}
                            </ul>
                        }
                        </div>
                    </div>
                    </div>
                    <div className="col-12 section-space--top--60">
                    <div className="comment-wrapper">
                        <h3>Deje su comentario</h3>
                        <div className="comment-form">
                        <form onSubmit={this.handleSubmit}>
                            <div className="row row-10">
                            <div className="col-md-6 col-12 section-space--bottom--20"><input type="text" placeholder="Nombre" disabled/></div>
                            <div className="col-md-6 col-12 section-space--bottom--20"><input type="email" placeholder="Email" disabled/></div>
                            <div className="col-12"><textarea placeholder="Mensaje" defaultValue={""} disabled/></div>
                            <div className="col-12"><input type="submit" defaultValue="Enviar comentario" disabled/></div>
                            </div>
                        </form>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BlogPostContent;
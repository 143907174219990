import PATHS from '../constants';

function fetchCounter() {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    return fetch(PATHS.ABOUT_US_COUNTER, {
        method: 'GET',
        dataType: 'json',
        headers,
    });
};

export default fetchCounter;
import React, { Component } from 'react';
import fetchBlogCategories from '../../fetch/blog-categories';
import fetchBlog from '../../fetch/home-blog';
import fetchBlogTags from '../../fetch//blog-tags';

class Sidebar extends Component{
    constructor(props){
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            searchText: "",
            blogCategories: [],
            popularPostData: [],
            blogTags: []
        }
    }
    
    getBlogCategories(){
        fetchBlogCategories()
            .then(response => response.json())
            .then((response) => { 
                this.setState({
                    blogCategories: response.data
                });
            });
    }

    getPopularPosts(){
        fetchBlog("&limit=3")
            .then(response => response.json())
            .then((response) => { 
                this.setState({
                    popularPostData: response.data
                });
            });
    }

    getBlogTags(){
        fetchBlogTags()
            .then(response => response.json())
            .then((response) => { 
                this.setState({
                    blogTags: response.data
                });
            });
    }

    componentDidMount(){
        this.getBlogCategories();
        this.getPopularPosts();
        this.getBlogTags();
    }

    handleSubmit (event) {
        event.preventDefault();
        this.props.filterPosts(this.state.searchText);
    }

    render(){

        /* sidebar category */

        let categoryDataList = this.state.blogCategories.map((categoryData, i)=>{
            return(
                <li key={i}><a onClick={() => (!this.props.details && this.props.filterPostsByCategory(categoryData.id))}>{categoryData.nombre}</a></li>
            )
        });
        
        /* sidebar popular post */
        
        let popularPostDataList = this.state.popularPostData.map((val, i)=>{
            return(
                <div className="sidebar-blog" key={i}>
                <a href={`/blog-details/${val.id}`} className="image"><img src={val.portada.data.full_url} alt="" /></a>
                    <div className="content">
                        <h5>
                            <a href={`/blog-details/${val.id}`}>{val.titulo}</a>
                        </h5>
                        <span>{val.fecha_creacion}</span>
                    </div>
                </div>
            )
        });

        
        /* sidebar tag */

        let tagDataList = this.state.blogTags.map((tag, i)=>{
            return(
                <li key={i}><a onClick={() => !this.props.details && this.props.filterPostsByTag(tag.nombre)}>{tag.nombre}</a></li>
            )
        });

        return(
            <div>
                <div className="sidebar-wrapper">
                    {   !this.props.details &&
                        <div className="sidebar" id="search">
                            <h3 className="sidebar-title">Buscar</h3>
                            <div className="sidebar-search">
                                <form onSubmit={this.handleSubmit}>
                                    <input value={this.state.searchText} type="text" placeholder="Search" onChange={(evt) => this.setState({searchText: evt.target.value}) } />
                                    <button type='submit'><i className="ion-ios-search"/></button>
                                </form>
                            </div>
                        </div>
                    }
                    <div className="sidebar">
                        <h3 className="sidebar-title">Categorias</h3>
                        <ul className="sidebar-list">
                            {categoryDataList}
                        </ul>
                    </div>
                    <div className="sidebar">
                        <h3 className="sidebar-title">Publicaciones populares</h3>
                        {popularPostDataList}
                    </div>
                    <div className="sidebar">
                        <h3 className="sidebar-title">Etiquetas Populares</h3>
                        <ul className="sidebar-tag">
                            {tagDataList}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}


export default Sidebar;
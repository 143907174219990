import React, { Component } from 'react';
import fetchTeam from '../fetch/about-us-team';
class TeamMemberGrid extends Component{
    constructor (props) {
        super(props);
        this.state = {
          data: [],
        }
      }

      getData(){
        fetchTeam()
            .then(response => response.json())
            .then((response) => {
                if (response.data) {
                    this.setState({
                        data: response.data
                    });
                }
            });
    }

    componentDidMount(){
        this.getData();
    }
    
    render(){
        if (!this.state.data.length) {
            return (<div/>);
        }

        let Datalist = this.state.data.map((val, i)=>{
            return(
                <div className="col-lg-3 col-sm-6 col-12 section-space--bottom--30" key={i}>
                    <div className="team">
                        <div className="image">
                        <img src={val.imagen.data.full_url} alt=""/>
                        </div>
                        <div className="content">
                        <h3 className="title">{val.nombre}</h3>
                        <span>{val.designacion}</span>
                        <a href={'mailto:'+val.email} className="email">{val.email}</a>
                        <div className="social">
                            { val.facebook && <a href={val.facebook} className="facebook"><i className="fa fa-facebook" /></a> }
                            { val.twitter && <a href={val.twitter} className="twitter"><i className="fa fa-twitter" /></a> }
                            { val.linkedin && <a href={val.linkedin} className="linkedin"><i className="fa fa-linkedin" /></a> }
                            { val.googlePlus && <a href={val.googlePlus} className="google"><i className="fa fa-google-plus" /></a> }
                        </div>
                        </div>
                    </div>
                </div>
            )
        });
        return(
            <div id='team'>
                <div className="team-member-area section-space--inner--120">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area text-center">
                        <h2 className="section-title section-space--bottom--50">{this.props.titulo}</h2>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="team-member-wrapper">
                        <div className="row">
                            {Datalist}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}

export default TeamMemberGrid;
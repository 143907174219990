import PATHS from '../constants';

function fetchContactForm(values) {
    const data = {
        nombre: values.nombre,
        correo: values.email,
        numero: values.telefono,
        descripcion: values.message,
        region: values.region
      };
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      };
      return fetch(PATHS.CONTACT_FORM, requestOptions)
    }

export default fetchContactForm;
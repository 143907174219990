import React, { useState } from 'react';
import fetchContactForm from '../fetch/contact_us_form';

function ContactForm() {
    const [values, setValues] = useState({
        nombre: '',
        email: '',
        telefono: '',
        region: '',
        message: ''
      });
    
      const handleChange = event => {
        const { name, value } = event.target;
        setValues({ ...values, [name]: value });
      };
    
      const handleSubmit = event => {
        event.preventDefault();
        fetchContactForm(values)
      };

      return (
        <form id="contact-form" onSubmit={handleSubmit}>
          <div className="row row-10">
            <div className="col-md-6 col-12 section-space--bottom--20">
              <input name="nombre" type="text" placeholder="Nombre" value={values.nombre} onChange={handleChange} />
            </div>
            <div className="col-md-6 col-12 section-space--bottom--20">
              <input name="email" type="email" placeholder="Email" value={values.email} onChange={handleChange} />
            </div>
            <div className="col-md-6 col-12 section-space--bottom--20">
              <input name="telefono" type="text" placeholder="Teléfono" value={values.telefono} onChange={handleChange} />
            </div>
            <div className="col-md-6 col-12 section-space--bottom--20">
              <input name="region" type="text" placeholder="Región" value={values.region} onChange={handleChange} />
            </div>
            <div className="col-12">
              <textarea name="message" placeholder="Mensaje" value={values.message} onChange={handleChange} />
            </div>
            <div className="col-12">
              <button type="submit">Enviar</button>
            </div>
          </div>
        </form>
      );
}

export default ContactForm;

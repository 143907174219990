import React, { Component } from 'react';
import ModalVideo from 'react-modal-video'
import fetchAboutUs from '../fetch/home-about-us';

function getVideoId(link = '') {
    if (link.indexOf('v=') !== -1) {
        const id = link.split('v=')[1];
        return id.split('&')[0];
    }

    if (link.indexOf('youtu.be') !== -1) {
        const id = link.split('outu.be/')[1];
        return id.split('?')[0];
    }
    return "";
}

class VideoCta extends Component{

    constructor () {
        super()
        this.state = {
          isOpen: false,
          data: null
        }
        this.openModal = this.openModal.bind(this)
    }
    
    openModal () {
        this.setState({isOpen: true})
    }

    getData(){
        fetchAboutUs()
            .then(response => response.json())
            .then((response) => {
                this.setState({
                    data: response.data[0]
                });
            });
    }

    componentDidMount(){
        this.getData();
    }

    render(){
        return(
            this.state.data &&
            <div>
                <div className="video-cta-area section-space--inner--120">
                    <div className="container">
                        <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="video-cta-content">
                            <h4 className="video-cta-content__small-title">{this.state.data.encabezado}</h4>
                            <h3 className="video-cta-content__title">{this.state.data.titulo}</h3>
                            <p className="video-cta-content__text">{this.state.data.texto}</p>
                            { (this.state.data.boton_link && this.state.data.boton_texto ) && 
                              <a href={`${process.env.PUBLIC_URL}/${this.state.data.boton_link}`} className="ht-btn ht-btn--round">{this.state.data.boton_texto}</a>
                            }
                              </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1 col-md-6">
                            <div className="cta-video-image">
                            <div className="video-popup">
                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={getVideoId(this.state.data.video)} onClose={() => this.setState({isOpen: false})} />
                                <button onClick={this.openModal}>
                                    <div className="cta-video-image__image">
                                        <img src={this.state.data.imagen.data.full_url} className="img-fluid" alt="" />
                                    </div>
                                    <div className="cta-video-image__icon">
                                        <i className="ion-ios-play" />
                                    </div>
                                </button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default VideoCta;
import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import fetchServiceImages from '../../fetch/service-images';

class ServiceGallery extends Component{
    constructor(props){
        super(props);
        this.state = {
          data: null
        }
    }

    getData(){
        fetchServiceImages(this.props.serviceId)
            .then(response => response.json())
            .then((response) => {
                if (response.data) {
                    this.setState({
                        data: response.data
                    });
                }
            });
    }

    componentDidMount(){
        this.getData();
    }

    render(){
        if (!this.state.data) {
            return (<div/>);
        }

        const params = {
            slidesPerView : 1,
            loop: true,
            speed: 1000,
            navigation: {
                nextEl: '.ht-swiper-button-next',
                prevEl: '.ht-swiper-button-prev'
            },
            renderPrevButton: () => (
            <div className="ht-swiper-button-prev ht-swiper-button-nav"><i className="ion-ios-arrow-left" /></div>
            ),
            renderNextButton: () => (
            <div className="ht-swiper-button-next ht-swiper-button-nav"><i className="ion-ios-arrow-forward" /></div>
            )
        };

        let ImageGalleryDataList = this.state.data.map((val, i) => {
            return(
                <div className="swiper-slide service-gallery__single-slide" key={i}>
                    <div className="item">
                        <img src={val.imagen.data.full_url} className="img-fluid" alt="gallery data" />
                    </div>
                </div>
            )
        });


        return(
            <div>
                <div className="service-gallery">
                    <Swiper {...params}>
                        {ImageGalleryDataList}
                    </Swiper>
                </div>
            </div>
        )
    }
}

export default ServiceGallery;
import React, {Component} from 'react';
import fetchProjects from '../../fetch/projects';
class ProjectsGrid extends Component{
    constructor(props){
        super(props);
        this.state = {
          data: []
        }
      }
    
    getData(){
        fetchProjects()
            .then(response => response.json())
            .then((response) => {  
                this.setState({
                    data: response.data
                });
            });
    }

    componentDidMount(){
        this.getData();
    }


    render(){
        if (!this.state.data.length) {
            return (<div/>);
        }

        let Datalist = this.state.data.map((val, i)=> {
            return(
                <div className="col-lg-4 col-sm-6 col-12 section-space--bottom--30" key={i}>
                    <div className="service-grid-item service-grid-item--style2">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <a href={`${process.env.PUBLIC_URL}/project-details/${val.id}`}>
                            <img src={val.portada.data.full_url} className="img-fluid" alt="" />
                        </a>
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <h3 className="title">
                        <a href={`${process.env.PUBLIC_URL}/project-details/${val.id}`}>{val.titulo}</a>
                        </h3>
                        <p className="subtitle">{val.descripcion_corta}</p>
                        <a href={`${process.env.PUBLIC_URL}/project-details/${val.id}`} className="see-more-link">VER MAS</a>
                    </div>
                    </div>
                </div>
            )
        });

        return(
            <div className="page-wrapper section-space--inner--120">
                <div className="project-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="project-item-wrapper">
                                    <div className="row">
                                        {Datalist}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*
                        <div className="row section-space--top--60">
                            <div className="col">
                                <ul className="page-pagination">
                                    <li><a href="/"><i className="fa fa-angle-left" /> Prev</a></li>
                                    <li className="active"><a href="/">01</a></li>
                                    <li><a href="/">02</a></li>
                                    <li><a href="/">03</a></li>
                                    <li><a href="/"><i className="fa fa-angle-right" /> Next</a></li>
                                </ul>
                            </div>
                        </div>
                        */}
                    </div>
                </div>
            </div>
        )
    }
}


export default ProjectsGrid;
import React, {Component} from 'react';
import fetchServices from '../../fetch/services';

class Services extends Component{
    constructor(props){
        super(props);
        this.state = {
          data: []
        }
    }

    getData(){
        fetchServices()
            .then(response => response.json())
            .then((response) => {  
                this.setState({
                    data: response.data
                });
            });
    }

    componentDidMount(){
        this.getData();
    }
    render(){
        if (!this.state.data.length) {
            return (<div/>);
        }
        let Datalist = this.state.data.map((val, i) => {
            return(
                <div className="col-lg-4 col-md-6 col-12 section-space--bottom--30" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <a href={`${process.env.PUBLIC_URL}/service-details/${val.id}`}>
                            <img src={val.portada && val.portada.data.full_url} className="img-fluid" alt="Service Grid" />
                        </a>
                        </div>
                        {
                            val.icon &&
                            <div className="icon">
                                <i className={val.icon} />
                            </div>
                        }
                       
                    </div>
                    <div className="service-grid-item__content">
                        <h3 className="title">
                        <a href={`${process.env.PUBLIC_URL}/service-details/${val.id}`}>{val.titulo}</a>
                        </h3>
                        <p className="subtitle">{val.descripcion_corta}</p>
                        <a href={`${process.env.PUBLIC_URL}/service-details/${val.id}`} className="see-more-link">VER MÁS</a>
                    </div>
                    </div>
                </div>
            )
        });

        return(
            <div className="page-wrapper section-space--inner--120">
                <div className="service-section">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="service-item-wrapper">
                            <div className="row">
                                {Datalist}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Services;
import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Sidebar from './components/Sidebar';
import BlogPostContent from './components/BlogPostContent';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import fetchBlogData from '../fetch/blog';

class BlogDetails extends Component{
    constructor(props) {
        super(props);
        this.state = {
            blogData: null
        };
    }

    formatearDescripcion(descripcion) {
        return descripcion.split('\n').map((text, i) => <p key={i}>{text}</p>);
    }

    getData(){
        fetchBlogData(this.props.match.params.id)
            .then(response => response.json())
            .then((response) => {
                const blogData = Object.assign(response.data, {
                    descripcion: this.formatearDescripcion(response.data.descripcion)
                });
                this.setState({
                    blogData
                });
            });
    }

    componentDidMount(){
        this.getData();
    }
    render(){

        return(
            <div>
                <NavBar/>
                {   this.state.blogData &&
                    <div>
                        <div className="breadcrumb-area breadcrumb-bg" style={{backgroundImage: this.state.blogData.portada && `url(${this.state.blogData.portada.data.full_url})`}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div className="page-banner text-center">
                                            <h1>{this.state.blogData.titulo}</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="page-wrapper section-space--inner--120">
                        <div className="blog-section">
                            <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-12 order-1 order-lg-2">
                                    <BlogPostContent post={this.state.blogData}/>
                                </div>
                                <div className="col-lg-4 col-12 order-2 order-lg-1">
                                    <Sidebar details={true}/>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                }

                <Footer/>

                <MobileMenu/>

            </div>
        )
    }
}


export default BlogDetails;
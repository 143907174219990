import React, { Component } from 'react';
import fetchFeatures from '../fetch/about-us-features';

class FeatureIcon extends Component{
    constructor () {
        super()
        this.state = {
          data: []
        }
      }

      getData(){
        fetchFeatures()
            .then(response => response.json())
            .then((response) => {
                if (response.data) {
                    this.setState({
                        data: response.data
                    });
                }
            });
    }

    componentDidMount(){
        this.getData();
    }
    render(){

        if (!this.state.data.length) {
            return (<div/>);
        }

        let Datalist = this.state.data.map((val, i)=>{
            return(
                <div className="col-lg-4 col-md-6 col-12 section-space--bottom--30" key={i}>
                    <div className="feature">
                    <div className="icon">
                        <img src={val.icono.data.full_url} className="img-fluid" alt="" />
                    </div>
                    <div className="content">
                        <h3>{val.titulo}</h3>
                        <p>{val.descripcion}</p>
                    </div>
                    </div>
                </div>
            )
        });

        return(
            <div>
                <div className={`feature-section section-space--inner--100 ${this.props.background}`}>
                    <div className="container">
                        <div className="col-lg-12">
                            <div className="feature-item-wrapper">
                                <div className="row">
                                    {Datalist}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FeatureIcon;
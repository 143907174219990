import React, {Component} from 'react';
import Swiper from 'react-id-swiper';
import fetchProjects from '../fetch/home-projects';

class ServiceGridSlider extends Component{
    constructor(props){
        super(props);
        this.state = {
          data: []
        }
      }
    
    getData(){
        fetchProjects()
            .then(response => response.json())
            .then((response) => {  
                this.setState({
                    data: response.data
                });
            });
    }

    componentDidMount(){
        this.getData();
    }

    render(){
        if (!this.state.data.length) {
            return (<div/>);
        }

        const params = {
            slidesPerView : 3,
            loop: true,
            speed: 1000,
            watchSlidesVisibility: true,
            spaceBetween : 30,
            autoplay: {
                delay: 3000,
            },
            // Responsive breakpoints
            breakpoints: {
                1499:{
                    slidesPerView : 3
                },

                991:{
                    slidesPerView : 2
                },

                767:{
                    slidesPerView : 1
                },

                575:{
                    slidesPerView : 1
                }
            }

        }

        let DataList = this.state.data.map((val, i)=>{
            return(
                <div className="swiper-slide" key={i}>
                    <div className="service-grid-item service-grid-item--style2">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <a href={`${process.env.PUBLIC_URL}/project-details/${val.id}`}>
                            <img src={val.portada.data.full_url} className="img-fluid" alt="" />
                        </a>
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <h3 className="title">
                        <a href={`${process.env.PUBLIC_URL}/project-details/${val.id}`}>{val.titulo}</a>
                        </h3>
                        <p className="subtitle">{val.descripcion_corta}</p>
                        <a href={`${process.env.PUBLIC_URL}/project-details/${val.id}`} className="see-more-link">VER MAS</a>
                    </div>
                    </div>
                </div>
            )
        });
        return(
            <div>
                <div className="service-slider-title-area grey-bg section-space--inner--top--120 section-space--inner--bottom--285">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="section-title-area text-center">
                            <h2 className="section-title mb-0">Proyectos</h2>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="service-grid-slider-area">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="service-slider">
                            <div className="service-slider__container service-slider__container--style2">
                                <Swiper {...params}>
                                    {DataList}
                                </Swiper>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default ServiceGridSlider;
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import fetchHome from '../fetch/home';

class MobileMenu extends Component{

    state = {
        active : false,
        homeData: null
    }

    toggleMobileMenu = () => {
        this.setState({
            active: !this.state.active
        })
    }

    getHomeData(){
        fetchHome()
            .then(response => response.json())
            .then((response) => { 
                if (response.data) {
                    this.setState({
                        homeData: response.data[0]
                    });
                } 
            });
    }

    componentDidMount() {
        this.getHomeData();
        var offCanvasNav = document.getElementById('offcanvas-navigation');
        var offCanvasNavSubMenu = offCanvasNav.querySelectorAll('.sub-menu');

        for (let i = 0; i<offCanvasNavSubMenu.length; i++){
           offCanvasNavSubMenu[i].insertAdjacentHTML("beforebegin", "<span class='menu-expand'><i></i></span>");
        }

        var menuExpand = offCanvasNav.querySelectorAll('.menu-expand');
        var numMenuExpand = menuExpand.length;

        function sideMenuExpand() {

            if(this.parentElement.classList.contains('active') === true) {
                this.parentElement.classList.remove('active');
            } else {
                for (let i = 0; i < numMenuExpand; i++) {
                    menuExpand[i].parentElement.classList.remove('active');
                }
                this.parentElement.classList.add('active');
            }
        }

        for (let i = 0; i < numMenuExpand; i++) {
            menuExpand[i].addEventListener("click", sideMenuExpand);
        }
    }


    render(){
        const homeData = this.state.homeData;
        return(
            <div>
                <div className= {`offcanvas-mobile-menu ${this.state.active ? 'active': ''} `} id="mobile-menu-overlay">
                    <a href="#/" className="offcanvas-menu-close" id="mobile-menu-close-trigger" onClick={this.toggleMobileMenu}>
                        <i className="ion-android-close" />
                    </a>
                    <div className="offcanvas-wrapper">
                        <div className="offcanvas-inner-content">
                            <div className="offcanvas-mobile-search-area">
                                <form action="#">
                                <input type="search" placeholder="Search ..." />
                                <button type="submit"><i className="fa fa-search" /></button>
                                </form>
                            </div>
                            <nav className="offcanvas-navigation" id="offcanvas-navigation">
                                <ul>
                                    <li>
                                    <Link to='/'>{(homeData && homeData.menu_home && homeData.menu_home.toUpperCase()) || 'HOME'}</Link>
                                    </li>
                                    <li><Link to='/about-us'>{(homeData && homeData.menu_about_us && homeData.menu_about_us.toUpperCase()) || 'ABOUT'}</Link></li>
                                    <li><Link to='/services'>{(homeData && homeData.menu_services && homeData.menu_services.toUpperCase()) || 'SERVICIOS'}</Link></li>
                                    <li><Link to='/projects'>{(homeData && homeData.menu_projects && homeData.menu_projects.toUpperCase()) || 'PROYECTOS'}</Link></li>
                                    <li><Link to='/blog'>{(homeData && homeData.menu_blog && homeData.menu_blog.toUpperCase()) || 'BLOG'}</Link></li>
                                    <li><Link to='/contact-us'>{(homeData && homeData.menu_contact_us && homeData.menu_contact_us.toUpperCase()) || 'CONTACTO'}</Link> </li>
                                </ul>
                            </nav>
                            { homeData &&  <div className="offcanvas-widget-area">
                                <div className="off-canvas-contact-widget">
                                <div className="header-contact-info">
                                    <ul className="header-contact-info__list">
                                    <li><i className="ion-android-phone-portrait" /> <a>{homeData.telefono}</a></li>
                                    <li><i className="ion-android-mail" /> <a href={"mailto:" +homeData.email}>{homeData.email}</a></li>
                                    </ul>
                                </div>
                                </div>
                                <div className="off-canvas-widget-social">
                                        { homeData.facebook && <a href={homeData.facebook}><i className="fa fa-facebook" /></a>}
                                        { homeData.twitter && <a href={homeData.twitter}><i className="fa fa-twitter" /></a>}
                                        { homeData.vimeo && <a href={homeData.vimeo}><i className="zmdi zmdi-vimeo" /></a>}
                                        { homeData.linkedin && <a href={homeData.linkedin}><i className="fa fa-linkedin" /></a>}
                                        { homeData.skype && <a href={homeData.skype}><i className="zmdi zmdi-skype" /></a>}
                                        { homeData.instagram && <a href={homeData.instagram}><i className="fa fa-instagram" /></a>}
                                        { homeData.youtube && <a href={homeData.youtube}><i className="zmdi zmdi-youtube" /></a>}
                                </div>
                            </div> }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MobileMenu;
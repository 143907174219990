import React, {Component} from 'react';
import NavBar from './components/NavBar';
import HeroSliderTwo from './components/HeroSliderTwo';
import VideoCta from './components/VideoCta';
import ProjectSliderTwo from './components/ProjectSliderTwo';
import ServiceTab from './components/ServiceTab';
import TestimonialSlider from './components/TestimonialSlider';
import TeamJob from './components/TeamJob';
import BlogGrid from './components/BlogGrid';
import BrandLogoSlider from './components/BrandLogoSlider';
import Footer from './components/Footer';
import MobileMenu from './components/MobileMenu';
import fetchHome from './fetch/home';
class HomeTwo extends Component{
    constructor(props){
        super(props);
        this.state = {
          data: null
        }
      }
    
    getData(){
        fetchHome()
            .then(response => response.json())
            .then((response) => { 
                if (response.data) {
                    this.setState({
                        data: response.data[0]
                    });
                } 
            });
    }

    componentDidMount(){
        this.getData();
    }
    render(){
        
        return(
            <div>
                {/* Navigation bar */}
                <NavBar data={this.state.data}/>
                {   this.state.data && 
                    <div>
                        {/* Hero slider */}
                        <HeroSliderTwo/>
                        
                        {/* Video CTA */}
                        <VideoCta/>
                        
                        {/* Project Slider */}
                        <ProjectSliderTwo/>
                        
                        {/* Service Tab */}
                        <ServiceTab/>
                        
                        {/* Testimonial Slider */}
                        <TestimonialSlider fondo=""/>
                        
                        {/* Team job */}
                        <TeamJob data={this.state.data}/>

                        {/* Brand logo */}
                        <BrandLogoSlider background = "grey-bg" />
                        
                        {/* Blog grid */}
                        <BlogGrid  background = "" />
                    </div>
                }
                {/* Footer */}
                <Footer data={this.state.data}/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default HomeTwo;
import React, { Component } from 'react';
import fetchJobs from '../fetch/home-jobs';

class TeamJob extends Component{
    constructor(props){
        super(props);
        this.state = {
          data: []
        }
      }
    
    getData(){
        fetchJobs()
            .then(response => response.json())
            .then((response) => {  
                this.setState({
                    data: response.data
                });
            });
    }

    componentDidMount(){
        this.getData();
    }

    render(){
        let DataList = this.state.data.length && this.state.data.map((val, i)=>{
            return(
                <div className="team-job__single" key={i}>
                    <h3 className="title"> <a href={val.link}>{val.titulo}</a></h3>
                    <p className="text">{val.descripcion_corta}</p>
                </div>
            )
        });
        return(
            <div>
                <div className="team-job-area section-space--inner--120">
                <div className="container">
                    <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="team-job__content">
                        <div className="team-job__title-wrapper">
                            <h2 className="team-job__title">{this.props.data.equipo_titulo}</h2>
                            <a href={this.props.data.equipo_link_redirect}><button className="see-more-link see-more-link--color">{this.props.data.equipo_link_texto}</button></a>
                        </div>
                        {   this.state.data.length ?
                            <div className="team-job__content-wrapper">
                                <h2 className="team-job__title">{this.props.data.ofertas_titulo}</h2>
                                <div className="team-job__list-wrapper">
                                    {DataList}
                                </div>
                            </div> :
                            ''
                        }
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="team-job__image text-center text-lg-right">
                        <img src={this.props.data.equipo_imagen && this.props.data.equipo_imagen.data.full_url} className="img-fluid" alt="" />
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}

export default TeamJob;
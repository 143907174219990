import React, {Component} from 'react';
import Swiper from 'react-id-swiper';
import fetchSlider from '../fetch/home-slider';

class HeroSliderTwo extends Component{
    constructor(props){
        super(props);
        this.state = {
          data: null
        }
      }
    
    getData(){
        fetchSlider()
            .then(response => response.json())
            .then((response) => {  
                this.setState({
                    data: response.data
                });
            });
    }

    componentDidMount(){
        this.getData();
    }

    render(){
        const params = {
            slidesPerView : 1,
            loop: true,
            speed: 1000,
            watchSlidesVisibility: true,
            effect: 'fade',
            navigation: {
                nextEl: '.ht-swiper-button-next',
                prevEl: '.ht-swiper-button-prev'
            },
            renderPrevButton: () => (
                <div className="ht-swiper-button-prev ht-swiper-button-nav d-none d-xl-block"><i className="ion-ios-arrow-left" /></div>
              ),
              renderNextButton: () => (
                <div className="ht-swiper-button-next ht-swiper-button-nav d-none d-xl-block"><i className="ion-ios-arrow-forward" /></div>
              ),
            autoplay: {
                delay: 5000,
            }
        }

        let DataList = this.state.data && this.state.data.map((val, i)=>{
            return(
                <div className="swiper-slide" key={i}>
                    <div className="hero-slider__single-item" style={{ backgroundImage: `url(${val.imagen.data.full_url})` }}>
                        <div className="hero-slider__content-wrapper">
                            <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                <div className="hero-slider__content m-auto text-center">
                                    <h2 className="hero-slider__title">{val.titulo}</h2>
                                    <p className="hero-slider__text">{val.texto}</p>
                                    {   (val.texto_boton && val.link) &&
                                        <a className="hero-slider__btn hero-slider__btn--style2" href={val.link}> {val.texto_boton} </a>
                                    }
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });

        return(
            <div>
                <div className="hero-alider-area">
                {
                    this.state.data &&
                    <Swiper {...params}>
                        {DataList}
                    </Swiper>
                }
                </div>
            </div>
        )
    }
}

export default HeroSliderTwo;